@charset "utf-8";

/* default */
html, body, main {height:100%;}
body {overflow-x:hidden;height:100%;font-family:'Spoqa Han Sans Neo','Josefin Sans', 'Nanum Myeongjo', 'sans-serif';font-size:12px;font-weight:400;line-height:1.3;color:#4b4c4c;background-color:#fff;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-osx-font-smoothing:gratranslate3d(0,0,0)yscale;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,fieldset,p,button,iframe,th,td{margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;margin:0;padding:0;}
h1,h2,h3,h4,h5,h6,dt{font-weight:normal;}
img,fieldset,iframe,button{border:0 none;}
button{cursor:pointer;background:none}
button:focus{outline:0;}
li{list-style:none;}
button{border:0 none}
table{ border-collapse:collapse;border-spacing:0; }
th, td{border-collapse:collapse;}
legend, caption, hr{display:none;}
select, input, textarea {}
i, em, u, cite {font-style:normal;}
strong{font-weight:normal;}
img{vertical-align:top;max-width:100%;}
a{color:#4b4c4c;text-decoration:none;outline-offset:-1px;transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s; cursor: pointer}

/* common */
main {overflow-x:hidden;-webkit-overflow-scrolling:touch;}
section {max-width:1440px;margin:0 auto;}
#contents {position:relative;min-width:320px;margin:0 auto;min-height:100%;background:#f8f8f9;}
#contents a {transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}
#contents a:hover,
#contents a:focus {opacity:.8;}
#root {
    height: 100%;
}
.br {display:block;}

/* header */
header {background:#fcfcfd;box-shadow:0 2px 20px 0 rgba(0, 0, 0, 0.06);-webkit-box-shadow:0 2px 20px 0 rgba(0, 0, 0, 0.06);-ms-box-shadow:0 2px 20px 0 rgba(0, 0, 0, 0.06);}
.header-inner {overflow:hidden;max-width:1040px;margin:0 auto;padding:23px 0;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.header-inner:after {display:block;clear:both;content:'';}
.header-inner h1 {float:left;padding:3px 0;text-indent:-9999px;background:url('./assets/images/logo.png') 50% no-repeat;background-size:auto 28px;width:148px;height:28px;}
.header-inner h1 a {display:block;height:100%;}
.header-inner h1 a:hover,
.header-inner h1 a:focus {opacity:.2;}
.header-inner > div {float:right;font-size:0;}
.header-inner .btn-round {display:block;width:78px;border-radius:6px;border:1px solid #4ae6e6;font-size:14px;font-weight:500;line-height:32px;text-align:center;color:#4ae6e6;}

.flex {display:flex;align-items:center;justify-content:space-between;}
.btn-type01 {display:inline-block;width:136px;border-radius:10px;font-size:16px;text-align:center;}
.btn-type01.full {background:#4ae6e6;line-height:48px;color:#fff;}
.btn-type01.line {background:#fff;line-height:46px;color:#4ae6e6;border:1px solid #4ae6e6;}


/* content */

.claim {width:600px;margin:80px auto 0;padding-bottom:120px;}
.claim .tit {display:block;font-size:16px;font-weight:bold;line-height:1.75;color:#000;}
.claim .shadow-box {margin-top:16px;padding:20px 0;border-radius:20px;box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);-webkit-box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);-ms-box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);background:#fff;}
.claim .shadow-box > span {display:block;padding-top:116px;background:url('./assets/images/bg_coin-default.png') 50% 0 no-repeat;font-size:12px;line-height:1.33;text-align:center;color:#a3a3ab;}
.claim .shadow-box > p {margin:24px 24px 0;border-radius:10px;border:1px solid #e6e8ec;padding:42px 0;font-size:14px;line-height:1.71;text-align:center;color:#000;}
.claim .shadow-box .btn-type01 {display:block;margin:32px auto 0;width:160px;border-radius:12px;background:#4ae6e6;font-size:16px;font-weight:bold;line-height:48px;text-align:center;color:#fff;}
.claim .mytoken figure {overflow:hidden;width:96px;height:96px;border-radius:100%;margin:0 auto;}
.claim .mytoken .copy {font-size:0;text-align:center;}
.claim .mytoken .copy img {height:12px;}
.claim .mytoken p {overflow:hidden;display:inline-block;vertical-align:middle;font-size:14px;color:#000;font-weight: bold;}
.claim .mytoken button {vertical-align:middle;margin-left:8px;display: inline-flex;align-items: center;}

.unlock-schedule {margin-top:64px;}
.unlock-schedule .schedule-list li {position:relative;margin-top:16px;padding:24px 24px 16px;min-height:108px;background:#fff;border-radius:20px;box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);-webkit-box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);-ms-box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.unlock-schedule .left span {font-size:14px;line-height:1.29;color:#141416;}
.unlock-schedule .left span i {color:#777e90;}
.unlock-schedule .left > div {margin-top:20px;}
.unlock-schedule .left > div em {display:inline-block;vertical-align:top;width:36px;font-size:12px;font-weight:bold;color:#a3a3ab;}
.unlock-schedule .left > div a {display:inline-block;vertical-align:top;width:240px;font-size:12px;color:#a3a3ab;text-decoration:underline;word-break:break-all;}
.unlock-schedule .right {position:absolute;top:24px;right:24px;text-align:right;}
.unlock-schedule .right strong {font-size:20px;font-weight:bold;line-height:0.9;color:#000;}
.unlock-schedule .right strong span {font-size:14px;}
.unlock-schedule .right .btn-small {display:block;width:80px;margin:16px 0 0 auto;font-size:11px;line-height:26px;text-align:center;color:#fff;border-radius:5px;background:#4ae6e6;}
.unlock-schedule .right .btn-small.gray {color:#9fa4be;background:rgba(177, 181, 195, .2);}

.total {margin-top:40px;padding-top:40px;border-top:1px solid #dfdfdf;}
.total ul {padding:24px;background:#fff;border-radius:20px;box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);-webkit-box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);-ms-box-shadow:0 64px 64px -48px rgba(15, 15, 15, 0.1);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.total li {margin-top:16px;}
.total li:first-child {margin-top:0;}
.total li em {font-size:16px;font-weight:bold;line-height:1.75;color:#000;}
.total li strong {font-size:20px;font-weight:bold;line-height:0.9;text-align:right;color:#000;}
.total li strong i {font-size:14px;}

/* icon */
.ico-warning {background:url('./assets/images/ico_warning.png') 50% 0 no-repeat;background-size:auto 24px;}


/* popup */
.popup-wrap, .popup-wrap .dim {display:none;position:fixed;top:0;left:0;width:100%;height:100%;z-index:999;overflow-y:scroll;}
.pop-view.popup-wrap, .pop-view.popup-wrap .dim {display:block;}
.popup-wrap .dim {background:rgba(20, 20, 22, 0.3);z-index:0;}
.popup-wrap > div:not(.dim) {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}

.popup-wrap .pop-type01 {padding:32px 28px 32px;background:#fff;border-radius:20px;box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.12), 0 64px 64px -48px rgba(31, 47, 70, 0.12);-webkit-box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.12), 0 64px 64px -48px rgba(31, 47, 70, 0.12);-ms-box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.12), 0 64px 64px -48px rgba(31, 47, 70, 0.12);background:#fcfcfd;text-align:center;}
.popup-wrap .pop-claim p {font-size:16px;line-height:1.38;text-align:center;color:#3f4246;}
.popup-wrap .btn-box {font-size:0;}
.popup-wrap .btn-type01 {margin:0 8px;}

.popup-wrap .pop-claim01 {width:360px;}
.popup-wrap .pop-claim01 .ico-warning {padding-top:40px;}
.popup-wrap .pop-claim01 .btn-box {margin-top:24px;}
.popup-wrap .pop-claim02 span {display:block;margin-top:24px;font-size:14px;line-height:1.29;color:#141416;}
.popup-wrap .pop-claim02 strong {display:block;margin-top:8px;font-size:20px;font-weight:bold;line-height:0.9;color:#000;}
.popup-wrap .pop-claim02 strong i {font-size:14px;}
.popup-wrap .pop-claim02 .btn-box {margin-top:40px;}


/* footer */
footer {position:absolute;left:0;bottom:0;width:100%;}
footer .copy {font-size:11px;line-height:96px;text-align:center;color:#6d7783;}


@media (max-width: 1040px) {
    .header-inner {padding:23px;}
}

@media (max-width: 768px) {
    .claim {padding:0 35px 120px;width:auto;}
}

